
import * as storyblokRuntime$wOkoSsYr0n from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/storyblok'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "storyblok",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$wOkoSsYr0n, defaults: {"baseURL":"https://a.storyblok.com"} }
}
        